<template>
    <div class="card card-custom h-100">
        <!--begin::Body-->
        <div class="card-header m-0">
            <div class="card-title">
                <span class="card-icon">
                    <i class="flaticon-open-box text-primary"></i>
                </span>
                <h3 class="card-label">
                    Riwayat Pullchick
                </h3>
            </div>
            <div class="card-toolbar">
                <button
                class="btn btn-size-sm btn-clean font-weight-bold m-3"
                type="button"
                @click="closeDialog()">
                    <i class="la la-close p-0"></i>
                </button>
            </div>
        </div>

        <perfect-scrollbar
        class="scroll"
        style="max-height: 89%; position: relative;"
        >

            <div v-if="!isBusy" class="card-body py-2">
                <!--begin::Top-->
                <div v-if="setting">
                    <div class="d-flex align-items-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-45 symbol-light mr-5">
                            <span class="symbol-label">
                                <span class="svg-icon svg-icon-lg svg-icon-primary">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                            </span>
                        </div>
                        <!--end::Symbol-->

                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-grow-1">
                            <span class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Setting HE</span>
                            <div class="d-flex flex-wrap">
                                <div class="d-flex align-items-center pr-5">
                                    <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ setting.tgl_setting_he | dateIndonesia }}</span>
                                </div>
                                <div class="d-flex">
                                    <b-badge :variant="setting.sts.color">{{ setting.sts.uraian }}</b-badge>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="d-flex align-items-center pr-5">
                                    <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Layout/Layout-grid.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="text-muted font-weight-bold">{{ setting.jumlah | nominal }} butir</span>
                                </div>
                            </div>
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::Top-->

                    <!--begin::Bottom-->
                    <div class="pt-3 p-0">
                        <!--begin::Text-->
                        <p v-if="setting.keterangan" class="text-dark-75 font-size-lg font-weight-normal pt-5 mb-6">
                            {{ setting.keterangan }}
                        </p>
                        <!--end::Text-->

                        <!--begin::Item-->
                        <div v-if="items">
                            <div v-for="(val, key) in items" :key="key" class="d-flex pt-5 px-5 mb-2 bg-hover-light rounded">
                                <!--begin::Symbol-->
                                <div class="symbol d-none d-sm-flex symbol-40 symbol-light-success mr-5 mt-1">
                                    <span class="symbol-label">
                                    <span class="svg-icon svg-icon-info svg-icon-md">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Devices/Homepod.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>         
                                    </span>
                                </div>
                                <!--end::Symbol-->

                                <!--begin::Info-->
                                <div class="d-flex flex-column flex-row-fluid">
                                    <!--begin::Info-->
                                    <div class="d-flex align-items-center flex-wrap">
                                        <span class="text-dark-75 text-hover-primary mb-1 font-size-md font-weight-bolder pr-6">
                                            PullChick {{ val.tgl_pullchick | dateIndonesia }}
                                        </span>
                                        <div v-if="setting.status == 52 && val.status == 170" class="ml-auto">
                                            <span 
                                            v-b-tooltip.hover title="Edit Pullchick"
                                            @click="openDialogForm('FormPullChick', {_id: val._id, action: 'edit'})"
                                            class="svg-icon p-2 m-0 svg-icon-primary">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                            
                                            <span 
                                            v-b-tooltip.hover title="Hapus Pullchick"
                                            @click="deletePopupData(val)"
                                            class="svg-icon p-2 m-0 svg-icon-primary">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/General/Trash.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </div>
                                        <div v-if="val.status == 200" class="ml-auto">
                                            <span 
                                            v-b-tooltip.hover title="Ajukan Perbaikan"
                                            @click="openDialogForm('FormPerbaikan', {_id: val._id, _sid: setting._id, action: 'perbaikan', kategori: 'pullchick'})"
                                            class="svg-icon p-2 m-0 svg-icon-warning">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/General/Unlock.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>

                                            <span 
                                            v-if="val.last_versioning_count"
                                            v-b-tooltip.hover title="Batalkan Perbaikan"
                                            @click="openDialogForm('FormPerbaikan', {_id: val._id, _sid: setting._id, action: 'pembatalan', kategori: 'pullchick'})"
                                            class="svg-icon p-2 m-0 svg-icon-primary">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/General/Trash.svg"/>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center flex-wrap">
                                        <span class="text-primary font-weight-bold mr-2">
                                            Menetas:
                                        </span>
                                        <span class="text-muted font-weight-bold">{{ val.menetas | nominal }} ekor</span>
                                    </div>

                                    <div class="d-flex align-items-center flex-wrap">
                                        <span class="text-primary font-weight-bold mr-2">
                                            Fertil:
                                        </span>
                                        <span class="text-muted font-weight-bold">{{ val.fertil | nominal }}%</span>
                                    </div>

                                    <div class="d-flex align-items-center flex-wrap">
                                        <span class="text-primary font-weight-bold mr-2">
                                            Culling:
                                        </span>
                                        <span class="text-muted font-weight-bold">{{ val.culling | nominal }} ekor</span>
                                    </div>

                                    <div v-for="(itm, ids) in val.saleable" :key="ids" class="d-flex align-items-center flex-wrap">
                                        <span class="text-primary font-weight-bold mr-2">
                                            {{ itm.sex }}
                                        </span>
                                        <span class="text-muted font-weight-bold">{{ itm.jumlah | nominal }} ekor</span>
                                    </div>

                                    <div class="d-flex align-items-center flex-wrap">
                                        <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                                    </div>

                                    <span v-if="val.peringatan" class="text-danger font-size-sm font-weight-normal">
                                        {{ val.peringatan }}
                                    </span>
                                    <!--end::Info-->
                                    <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

                                </div>
                                <!--end::Info-->

                            </div>
                        </div>
                        <div v-else>
                            <span class="text-muted font-size-lg font-weight-bolder">
                                Tidak ada Pullchick
                            </span>
                        </div>
                        <!--end::Item-->
                    </div>
                    <!--end::Bottom-->
                </div>
            </div>
            <div v-else class="card-body">
                <FacebookLoader></FacebookLoader>
            </div>
        <!--end::Body-->
        </perfect-scrollbar>

        <div class="card-footer d-flex justify-content-between align-items-center py-2">                
            <button @click="dialogFilter = true" class="btn btn-light-success btn-sm rounded font-weight-bolder font-size-sm px-4 p-2">
                <span class="svg-icon svg-icon-sm">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Text/Filter.svg"/>
                    <!--end::Svg Icon-->
                </span>          
                Filter
            </button>

            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage"></b-pagination>
        </div>

        <v-dialog v-model="dialogFilter" persistent scrollable max-width="500px">
            <v-card class="px-4 h-100">
                <v-card-title class="headline">Filter Data Pullchick</v-card-title>
            
                <perfect-scrollbar
                class="scroll"
                style="max-height: 89%; position: relative;"
                >
                    <ValidationObserver ref="formFilter">
                    <b-form @submit="onSubmitFilter" v-if="show">
                        <v-card-text>
                            <div class="mb-7" role="group">
                            <label for="input-filter-1">Tanggal Pullchick:</label>
                            <b-button id="input-filter-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ showDate }}</b-button>
                            </div>

                            <validationProvider name="Status" v-slot="{ errors, valid }">
                                <b-form-group
                                    id="input-filter-group-2" 
                                    label="Status:" 
                                    label-for="input-filter-2"
                                    :invalid-feedback="errors[0]"
                                    :state="valid">
                                    <b-form-select2
                                    id="input-filter-2"
                                    v-model="filter.status"
                                    :options="opsStatus"
                                    required
                                    variant="solid"
                                    :state="valid"
                                    ></b-form-select2>
                                </b-form-group>
                            </validationProvider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <button 
                            @click="onSubmitFilter" 
                            ref="kt_submit_form_filter"
                            class="btn btn-primary font-weight-bold m-3">
                            <i class="la la-save"></i>
                            Filter
                            </button>

                            <button
                            class="btn btn-clean font-weight-bold m-3"
                            type="button"
                            @click="onResetFilter()">
                            <i class="la la-recycle"></i>
                            Reset
                            </button>

                            <button
                            class="btn btn-clean font-weight-bold m-3"
                            type="button"
                            @click="dialogFilter = false">
                            <i class="la la-close"></i>
                            Close
                            </button>
                        </v-card-actions>
                    
                    </b-form>
                    </ValidationObserver>
                </perfect-scrollbar>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
        </v-dialog>
    
        <v-dialog
            ref="dialogDate"
            v-model="modalDate"
            persistent
            width="290px"
        >
            <v-date-picker
            v-model="dates"
            scrollable
            range
            color="bg-primary"
            header-color="bg-primary"
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalDate = false"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="setDate()"
            >
                OK
            </v-btn>
            </v-date-picker>
        </v-dialog>

        <v-dialog
        v-model="dialogDelete"
        max-width="300">
            <v-card>
                <v-card-title class="headline">Hapus data</v-card-title>

                <v-card-text>
                    Apakah anda yakin akan menghapus data <b>{{(dataHapus ? `Pullchick tanggal ${$options.filters.dateIndonesia(dataHapus.tgl_pullchick)}` : '')}}</b> ? data yang dihapus tidak dapat dikembalikan lagi
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <b-button
                variant="link-success"
                class="m-3"
                @click="dialogDelete = false">
                    BATAL
                </b-button>

                <b-button
                variant="link-danger"
                class="m-3"
                @click="onDelete">
                    HAPUS
                </b-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import ErrorService from "@/core/services/error.service";

import FormPullChick from "@/view/pages/nasional/produksi/form/_PullChick.vue";
import FormPerbaikan from "@/view/pages/nasional/produksi/form/_AjukanPerbaikan.vue";

export default {
    name: "unggas-nasional-data-produksi-riwayat-pullchick",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
        FacebookLoader,
        FormPullChick,
        FormPerbaikan
    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            isBusy: true,
            show: true,
            dialogDetail: false,
            curComponent: null,
            sdataForm: null,
            modalDate: false,
            dialogFilter: false,
            dates: [],
            filter: {
                tgl_awal: null,
                tgl_akhir: null,
                status: null
            },
            setting: null,
            items: null,
            dialogDelete: false,
            dataHapus: null,
            
            opsStatus: [{
                value: null,
                text: 'Silahkan pilih Status',
            }],
        }
    },
    computed: {
        showDate() {
            return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
        }
    },
    methods: {
        setDate() {
            if (this.dates.length < 1) {
                ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan pilih tanggal terlebih dahulu',
                })
                return;
            }
            if (this.dates.length < 2) {
                this.dates.push(this.dates[0])
            }
            this.dates.sort()
            this.filter.tgl_awal = this.dates[0]
            this.filter.tgl_akhir = this.dates[1]
            this.modalDate = false
            this.errorDate = false
        }, 
        closeDialog() {
            return this.$emit('close-modal', { status: true, refresh: false })
        },
        openDialogForm(name, data) {
            this.curComponent = name
            this.sdataForm = data
            this.dialogDetail = true
        },
        closeDialogForm(res) {
        if (res.status) {
            if (res.refresh) {
                this.getData(this.currentPage)    
            }
            this.curComponent = null
            this.sdataForm = null
            this.dialogDetail = false
        }
        },
        changePage(val){
            if(this.currentPage == val) return
            this.isBusy = true
            this.items = null
            this.getData(val)
        },
        resetTable() {
            this.items = null
            this.totalRows = 0
            this.currentPage = 1
        },
        
        getStatus() {
            MasterService.getStatus()
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.opsStatus = [{
                        value: null,
                        text: "Silahkan pilih Status"
                    }]
                    return;
                }

                this.opsStatus = res.data.data
                this.opsStatus.push({
                    value: null,
                    text: "Silahkan pilih Status"
                })
            })
            .catch((err) => {
                this.opsStatus = [{
                    value: null,
                    text: "Tidak ada Status"
                }]
                ErrorService.status(err)
            })
        },
        getData (page = 1, perPage = this.perPage, search = this.filter) {
            this.isBusy = true
            ProduksiService.getRiwayatPullchick({setting:this.sdata._id, page, per_page: perPage, ...search})
            .then((res) => {
                if (!res.data.status) {
                    ErrorService.message(res.data)
                    this.resetTable()
                    this.isBusy = false
                    return;
                }
                this.setting = res.data.data.setting
                this.items = res.data.data.pullchick.data
                this.currentPage = res.data.data.pullchick.current_page
                this.totalRows = res.data.data.pullchick.total
                this.isBusy = false
            })
            .catch((err) => {
                this.resetTable()
                this.isBusy = false
                return ErrorService.status(err)
            })
        },
        deletePopupData(val) {
            this.dataHapus = val
            this.dialogDelete = true
        },
        onDelete () {
            if (! this.dataHapus) {
                return
            }
            ProduksiService.deletePullChick({ setting: this.sdata._id, pullchick: this.dataHapus._id })
            .then((res) => {
                if (res.data.status) { 
                this.getData(this.currentPage)              
                }
                ErrorService.message(res.data)
                this.dialogDelete = false
                this.dataHapus = null
            })
            .catch((err) => {
                this.dialogDelete = false
                this.dataHapus = null
                return ErrorService.status(err)
            })
        },
        removeSpinner(button) { 
            button.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        },
        onSubmitFilter(evt) {
            evt.preventDefault()
            this.$refs.formFilter.validate().then(success => {
                if (!success) {
                    ErrorService.message({
                        action: 'Peringatan',
                        message: 'Silahkan lengkapi form data terlebih dahulu',
                    })
                    return;
                }

                // set spinner to submit button
                const submitButton = this.$refs["kt_submit_form_filter"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                setTimeout(() => {
                    this.dialogFilter = false
                    this.getData(1)

                    this.removeSpinner(submitButton)
                }, 800);
            })
        },
        onResetFilter () {
            this.filter.tgl_awal = null
            this.filter.tgl_akhir = null
            this.filter.status = null
            this.dates = []

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
    },
    mounted() {
        this.getData()
    },
}
</script>