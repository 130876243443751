<template>
  <div data-app>
    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ sdata.action == 'edit' || sdata.action == 'perbaikan' ? 'Edit' : 'Tambah' }} Setting HE
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">  
        <perfect-scrollbar
        class="scroll"
        style="max-height: 98%; position: relative;"
        >
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <div class="mb-7" role="group">
              <label for="input-1">Tanggal:</label>
              <b-button id="input-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ form.tgl_setting | dateIndonesia }}</b-button>
              <div v-if="errorDate" class="text-danger mt-1"> The Tanggal Setting HE field is required </div>
            </div>

            <validationProvider :name="`Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-2`" 
              label="Jumlah:" 
              :label-for="`input-2`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.jumlah"
                  :min="0"
                  :max="9999999999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
            </validationProvider>

            <validationProvider :name="`Umur Simpan Telur`" rules="required|min_value:0|max_value:9999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-3`" 
              label="Umur Simpan Telur (Hari):" 
              :label-for="`input-3`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-3`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.egg_age"
                  :min="0"
                  :max="9999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
            </validationProvider>

            <validationProvider :name="`Strain`" rules="required" v-slot="{ errors, valid }">
            <b-form-group 
            id="input-group-6" 
            label="Menghasilkan Strain:" 
            label-for="input-6"
            :invalid-feedback="errors[0]"
            :state="valid">
                <b-form-select2
                id="input-6"
                v-model="form.strain"
                :options="opsStrain"
                required
                :state="valid"
                ></b-form-select2>
            </b-form-group>
            </validationProvider>

            <div class="separator separator-dashed separator-border-2 mt-1 mb-2"></div>

            <validationProvider :name="`Asal HE`" rules="required" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-2`" 
              label="Farm Asal HE:" 
              :label-for="`input-2`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <treeselect
                  :multiple="true"
                  required
                  :options="opsAsalHE"
                  :normalizer="normalizer"
                  :flatten-search-results="true"
                  placeholder="Pilih Asal Collecting HE"
                  v-model="form.asal"
                />
              </b-form-group>
            </validationProvider>

          </b-form>
          </ValidationObserver>

          <div class="mt-3">
            <div v-if="sdata.action == 'perbaikan'" class="mt-3">
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'draft')" 
                  ref="kt_submit"
                  class="btn btn-info font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan Draf
                </button>
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Ajukan
                </button>
                <button
                  v-if="buttonMenu == 'ajukan'" 
                  @click="onBackDraf" 
                  ref="kt_submit_draf"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Kembalikan Draf
                </button>
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
            <div v-else class="mt-3">
                <button 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
          </div>
            
        </perfect-scrollbar>
        </div>

      <!--end::Form-->
    </div>

    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
      >
        <v-date-picker
          v-model="date"
          scrollable
          color="bg-primary"
          header-color="bg-primary"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="changeDate()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "unggas-nasional-data-produksi-shr-add",
  props: {
    sdata: {
      required: true
    }
  },
  components: { 
    Treeselect
  },
  data() {
    return {
      loading: false,
      modalDate: false,
      errorDate: false,
      date: new Date().toISOString().slice(0,10),
      form: {
        _id: this.sdata._id || null,
        tgl_setting: new Date().toISOString().slice(0,10),
        jumlah: 0,
        strain: null,
        egg_age: 0,
        asal: [],
        status: 15,
        type: 'ajukan',
      },
      buttonMenu: 'draf',
      show: true,
      opsAsalHE: [],
      opsStrain: [{
          value: null,
          text: 'Silahkan pilih Strain',
      }],
    }
  },
  // watch: {
  //   'form.tgl_setting' (val, old) {
  //     if (val != old) {
  //       if(!this.loading) {
  //         this.form.asal = []
  //       }
  //       this.getProduksiEgg(val)
  //     }
  //   }
  // },
  methods: {
    onStayPage() {
      this.dialogEnd = false
      this.onReset()
    },
    closeDialog() {
        return this.$emit('close-modal', {status: true, refresh: false})
    },
    changeDate() {
      this.form.tgl_setting = this.date
      this.modalDate = false
    },
    normalizer(node) {
      if (!node) return
      return {
        id: node.value,
        label: `${node.text} ${this.$options.filters.dateIndonesia(node.tanggal)}`,
        children: node.childrens,
      }
    },
    // getProduksiEgg(tanggal) {
    //   if (!tanggal) {
    //     return
    //   }
    //   ProduksiService.getProduksiEgg({tanggal})
    //   .then((res) => {
    //     if (!res.data.status) {
    //         ErrorService.message(res.data)
    //         this.opsAsalHE = []
    //         this.loading = false
    //         return;
    //     }
    //     this.opsAsalHE = res.data.data
    //     this.loading = false
    //   })
    //   .catch((err) => {
    //     this.opsAsalHE = []
    //     this.loading = false
    //     return ErrorService.status(err)
    //   })
    // },
    getFarm() {
      PerusahaanService.getPerusahaanFarm()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsAsalHE = []
              this.loading = false
              return;
          }

          this.opsAsalHE = res.data.data
          this.loading = false
      })
      .catch((err) => {
          this.opsAsalHE = []
          this.loading = false
          return ErrorService.status(err)
      })

    },
    getTernakStrain() {
        MasterService.getTernakStrain()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsStrain = [{
                    value: null,
                    text: "Silahkan pilih Strain"
                }]
                return;
            }

            this.opsStrain = res.data.data
            this.opsStrain.push({
                value: null,
                text: "Silahkan pilih Strain"
            })
        })
        .catch((err) => {
            this.opsStrain = [{
                value: null,
                text: "Tidak ada Strain"
            }]
            ErrorService.status(err)
        })
    }, 
    getData () {
      const params = {
        setting: this.sdata._id,
      }

      let action = "getSettingHeEdit"
      if(this.sdata.action == 'perbaikan') {
        action = "getSettingHEPerbaikan"
        params._id = this.sdata._id
      }
      ProduksiService[action](params)
      .then((res) => {
        if (res.data.status) {

          this.form._id = this.sdata._id || null,
          this.form.tgl_setting = new Date(res.data.data.tgl_setting).toISOString().slice(0,10),
          this.form.jumlah = res.data.data.jumlah,
          this.form.strain = res.data.data.strain,
          this.form.egg_age = res.data.data.egg_age,
          this.form.asal = res.data.data.asal,
          this.date = new Date(res.data.data.tgl_setting).toISOString().slice(0,10)

          if(this.sdata.action == 'perbaikan') {
            this.form.setting_ajuan = res.data.data._setting
            this.form.status = res.data.data._ids
            if(this.form.status == 170) {
              this.buttonMenu = 'ajukan'
            }
          }
          return;
        }
        ErrorService.message(res.data)
        this.closeDialog()
      })
      .catch((err) => {
        this.loading = false
        ErrorService.status(err)
        this.closeDialog()
      })
    },
    removeSpinner(button) { 
        button.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
    },
    onSubmit(evt, ajukan) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            let action = "addSettingHE"
            if(this.sdata.action == 'edit') {
              action = 'updateSettingHE'
            }
            if(this.sdata.action == 'perbaikan') {
              if(ajukan == 'draf') {
                this.form.status = 15
                this.form.type = 'ajukan'
              }
              if(ajukan == 'ajukan') {
                this.form.status = 170
                this.form.type = 'ajukan'
              }
              action = 'updateSettingHEPerbaikan'
            }

            ProduksiService[action](this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.onReset()
                      this.$emit('close-modal', {status: true, refresh: true})
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.form.tgl_setting = new Date().toISOString().slice(0,10)
      this.form.asal = []
      this.form.jumlah = 0
      this.form.egg_age = 0
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onBackDraf (evt) {
        evt.preventDefault()
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_draf"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        ProduksiService.updateSettingHEPerbaikan({...this.form, status: 15, type: 'draf'})
        .then((res) => {
            if (res.data.status) { 
              this.form.status = 15
              this.form.type = 'draf'   
              this.buttonMenu = 'draf'   
            }
            ErrorService.message(res.data)
            this.removeSpinner(submitButton)
        })
        .catch((err) => {
            this.removeSpinner(submitButton)
            return ErrorService.status(err)
        })
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "SHR" }]);
    this.getTernakStrain()
    this.getFarm()
    if (this.sdata.action == 'edit' || this.sdata.action == 'perbaikan') {
      this.loading = true
      this.getData();
    }
    // else this.getProduksiEgg(this.form.tgl_setting)
  },
};
</script>