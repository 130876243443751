<template>
  <div data-app>
    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ sdata.action == 'edit' || sdata.action == 'perbaikan' ? 'Edit' : 'Tambah' }} Pullchick
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">
        <perfect-scrollbar
        class="scroll"
        style="max-height: 98%; position: relative;"
        >
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">

            <div class="mb-7" role="group">
              <label for="input-11">Tanggal:</label>
              <b-button id="input-11" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ form.tgl_pullchick | dateIndonesia }}</b-button>
              <div v-if="errorDate" class="text-danger mt-1"> The Tanggal Pull Chick field is required </div>
            </div>

            <validationProvider :name="`Fertil`" rules="required|min_value:0|max_value:9999999999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-3`" 
              label="Jml Fertil:" 
              :label-for="`input-3`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-3`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.fertil"
                  :min="0"
                  :max="9999999999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
              <span class="form-text text-muted">Menggunakan satuan butir.</span>
            </validationProvider>

            <validationProvider :name="`Jumlah Pullchick`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-2`" 
              label="Jml Pullchick:" 
              :label-for="`input-2`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.pullchick"
                  :min="0"
                  :max="9999999999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
            </validationProvider>

            <validationProvider :name="`Jumlah Culling`" rules="required|min_value:0|max_value:9999999999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-3`" 
              label="Jml Culling:" 
              :label-for="`input-3`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-3`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.culling"
                  :min="0"
                  :max="9999999999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
            </validationProvider>

            <validationProvider :name="`Hatch Of Windows`" rules="required|min_value:0|max_value:9999999999" v-slot="{ errors }">
              <b-form-group 
              :id="`input-group-4`" 
              label="Hatch Of Windows (Hours):" 
              :label-for="`input-4`"
              :invalid-feedback="errors[0]"
              :state="(errors.length == 0) ? true : false">
                <vue-number-input 
                  :attrs="{ id: `input-4`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                  v-model="form.how"
                  :min="0"
                  :max="9999999999"
                  required
                  center
                  controls
                ></vue-number-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Menghasilkan" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-5" 
                label="Menghasilkan:" 
                label-for="input-5"
                :invalid-feedback="errors[0]"
                :state="valid">
                <b-form-select2
                id="input-5"
                v-model="form.tipe"
                :options="opsTipe"
                required
                variant="solid"
                :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <b-row class="w-100 mx-0" v-for="(rinc, index) in form.saleable" :key="index">
                <b-col cols="10" class="pt-5 px-2 pb-0">
                    <h6 class="text-muted">Rinc. Saleable {{index + 1}}</h6>
                </b-col>
                <b-col cols="2" class="text-right pt-5 px-2 pb-0">
                    <b-button v-show="(form.saleable.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                    <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                    </b-button>
                </b-col>       
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-1`" 
                    label="Sex:" 
                    :label-for="`input-${index}-1`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <b-form-select2
                        :id="`input-${index}-1`"
                        v-model="rinc.sex"
                        :options="opsSex"
                        required
                        variant="solid"
                        :state="(errors.length == 0 && rinc.sex != null) ? true : null"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-3`" 
                    label="Jumlah:" 
                    :label-for="`input-${index}-3`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <vue-number-input 
                        :attrs="{ id: `input-${index}-3`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="rinc.jumlah"
                        :min="0"
                        :max="9999999999"
                        required
                        center
                        controls
                        ></vue-number-input>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Main Product`" rules="required" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-4`" 
                    :label-for="`input-${index}-4`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <b-form-checkbox
                        :id="`input-${index}-4`"
                        v-model="rinc.main"
                        :name="`input-${index}-4`"
                        value="1"
                        unchecked-value="0"
                      >
                        Main Product
                      </b-form-checkbox>

                    </b-form-group>
                    </validationProvider>
                </b-col>
            </b-row>

            <b-row class="w-100 mx-0">
                <b-col cols="12" class="text-right">
                    <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                    <i class="flaticon2-plus-1 p-0"></i>
                    Add Saleable
                    </b-button>
                </b-col>
            </b-row>

          </b-form>
          </ValidationObserver>

          <div class="mt-3">
            <div v-if="sdata.action == 'perbaikan'" class="mt-3">
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'draft')" 
                  ref="kt_submit"
                  class="btn btn-info font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan Draf
                </button>
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Ajukan
                </button>
                <button
                  v-if="buttonMenu == 'ajukan'" 
                  @click="onBackDraf" 
                  ref="kt_submit_draf"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Kembalikan Draf
                </button>
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
            <div v-else class="mt-3">
                <button 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
          </div>
        </perfect-scrollbar>  
        </div>
      <!--end::Form-->
    </div>
    
    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
      >
        <v-date-picker
          v-model="form.tgl_pullchick"
          scrollable
          :min="this.sdata.tgl_setting_he"
          color="bg-primary"
          header-color="bg-primary"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
             @click="modalDate = false"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "unggas-nasional-data-produksi-pullchick-add",
  props: {
      sdata: {
          required: true
      }
  },
  data() {
    return {
      loading: false,
      modalDate: false,
      errorDate: false,
      form: {
        _sid: this.sdata._id || null,
        _id: this.sdata._id || null,
        tgl_pullchick: new Date().toISOString().slice(0,10),
        pullchick: 0,
        fertil: 0,
        culling: 0,
        how: 0,
        tipe: null,
        saleable: [{
          sex: null,
          main: 1,
          jumlah: 0
        }],
        status: 15,
        type: 'ajukan',
      },
      buttonMenu: 'draf',
      show: true,
      opsTipe: [{
        value: null,
        text: 'Silahkan pilih Tipe Ternak'
      }],
      opsSex: [{
        value: null,
        text: 'Silahkan pilih Sex'
      }]
    }
  },
  watch: {
    'form.tipe'(val, old) {
      if (val != old) {
        if (!this.loading) {
          for (let index = 0; index < this.form.saleable.length; index++) {
            this.form.saleable[index].sex = null          
          }
        }

        this.getTernakSex(val)
      }
    }
  },
  methods: {
    addRincian(){
        this.form.saleable.push({
            sex: null,
            main: 1,
            jumlah: 0
        })
    },
    deleteRincian(index){
        if (this.form.saleable.length > 1) {
            this.form.saleable.splice(index,1);
        }
        else {
            this.form.saleable = [{
                    sex: null,
                    main: 1,
                    jumlah: 0
                }
            ]
        }
    },
    closeDialog() {
        return this.$emit('close-modal', {status: true, refresh: false})
    },
    getTernakTipe() {
      MasterService.getTernakTipe()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe Ternak"
              }]
              return;
          }

          this.opsTipe = res.data.data
          this.opsTipe.push({
              value: null,
              text: "Silahkan pilih Tipe Ternak"
          })
      })
      .catch((err) => {
          this.opsTipe = [{
              value: null,
              text: "Tidak ada Tipe Ternak"
          }]
          ErrorService.status(err)
      })
    },
    getTernakSex(val) {
      if (!val) {
        return
      }
      MasterService.getTernakSex({tipe: val})
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsSex = [{
                  value: null,
                  text: "Silahkan pilih Sex"
              }]
              this.loading = false
              return;
          }

          this.opsSex = res.data.data
          this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
          })
          this.loading = false
      })
      .catch((err) => {
        this.opsSex = [{
            value: null,
            text: "Tidak ada Sex"
        }]
        this.loading = false
        ErrorService.status(err)
      })
    },    
    getData () {
      const params = {
        pullchick: this.sdata._id,
      }

      let action = "getPullchickEdit"
      if(this.sdata.action == 'perbaikan') {
        action = "getPullchickPerbaikan"
        params._sid = this.sdata._sid
        params._id = this.sdata._id
      }

      ProduksiService[action](params)
      .then((res) => {
        if (res.data.status) {
          this.form._id = res.data.data._id
          this.form._sid = res.data.data._sid
          this.form.tgl_pullchick = new Date(res.data.data.tgl_pullchick).toISOString().slice(0,10)
          this.form.pullchick = res.data.data.pullchick
          this.form.fertil = res.data.data.fertil
          this.form.culling = res.data.data.culling
          this.form.how = res.data.data.how
          this.form.tipe = res.data.data.tipe
          this.form.saleable = res.data.data.saleable

          if(this.sdata.action == 'perbaikan') {
            this.form.pullchick_ajuan = res.data.data._pullchick
            this.form.status = res.data.data._ids
            if(this.form.status == 170) {
              this.buttonMenu = 'ajukan'
            }
          }
          return;
        }
        ErrorService.message(res.data)
        this.closeDialog()
      })
      .catch((err) => {
        this.loading = false
        ErrorService.status(err)
        this.closeDialog()
      })
    },
    removeSpinner(button) { 
        button.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
    },
    onSubmit(evt, ajukan) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            let action = "addPullChick"
            if (this.sdata.action == 'edit') action = 'updatePullChick'
            if(this.sdata.action == 'perbaikan') {
              if(ajukan == 'draf') {
                this.form.status = 15
                this.form.type = 'ajukan'
              }
              if(ajukan == 'ajukan') {
                this.form.status = 170
                this.form.type = 'ajukan'
              }
              action = 'updatePullChickPerbaikan'
            }

            ProduksiService[action](this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.onReset()
                      this.$emit('close-modal', {status: true, refresh: true})
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.dates = []
      this.form.tgl_pullchick = new Date().toISOString().slice(0,10)
      this.form.pullchick = 0
      this.form.fertil = 0
      this.form.culling = 0
      this.form.how = 0
      this.form.saleable = [{
        sex: null,
        main: 1,
        jumlah: 0
      }]
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onBackDraf (evt) {
        evt.preventDefault()
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_draf"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        ProduksiService.updatePullChickPerbaikan({...this.form, status: 15, type: 'draf'})
        .then((res) => {
            if (res.data.status) { 
              this.form.status = 15
              this.form.type = 'draf'   
              this.buttonMenu = 'draf'   
            }
            ErrorService.message(res.data)
            this.removeSpinner(submitButton)
        })
        .catch((err) => {
            this.removeSpinner(submitButton)
            return ErrorService.status(err)
        })
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "SHR" }]);
    await this.getTernakTipe()
    
    if (this.sdata.action == 'edit' || this.sdata.action == 'perbaikan') {
      this.loading = true
      await this.getData();
    }
  },
};
</script>
