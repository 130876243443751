<template>
  <div id="unggas-nasional-data-produksi-shr-list" data-app>

    <!--begin::Engage Widget 1-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0 bg-white">
            <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center"
                style="background-position: right bottom; background-size: auto 100%; background-image: url(media/svg/humans/custom-8.svg)">

                <div class="row w-100">
                    <div class="col-12 col-sm-9">
                        <button 
                            @click="$router.push('/unggas-nasional/produksi')" 
                            type="button" 
                            class="btn btn-link-primary font-weight-bolder px-8 font-size-lg mb-2"
                            v-b-tooltip.hover title="List Farm"
                        >
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            List Hatchery
                        </button>
                        <div class="separator separator-dashed separator-border-2 mb-5"></div>
                        <h4 class="text-danger font-weight-bolder">Search SHR</h4>
                        <div class="d-flex flex-center py-2 px-6 bg-light rounded">
                            <span class="svg-icon svg-icon-lg svg-icon-info">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                                <!--end::Svg Icon-->
                            </span>
                            <div @click="modalDate = true" class="form-control border-0 font-weight-bold pl-2 bg-light cursor-pointer">
                                <span class="text-muted">{{ dateView }}</span>
                            </div>
                            <span v-if="search" @click="resetDate()" class="svg-icon svg-icon-lg svg-icon-danger cursor-pointer">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Navigation/Close.svg" />
                                <!--end::Svg Icon-->
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Engage Widget 1-->

    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">SHR</span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">List Data Setting HE dan Pull Chickin</span>
          </h3>
          <div class="card-toolbar">
              <a v-b-tooltip.hover  @click="openDialogForm('RiwayatSettingHE', {_id: idH})" title="Riwayat Input" class="btn btn-primary font-weight-bolder font-size-sm mr-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                    <!--end::Svg Icon-->
                </span>
                <span class="d-none d-sm-inline">Riwayat</span>                
              </a>
              <a v-b-tooltip.hover @click="openDialogForm('FormSettingHE', {_id: idH, action: 'add'})" title="Tambah Setting HE" class="btn btn-success font-weight-bolder font-size-sm">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Layout/Layout-grid.svg" />
                    <!--end::Svg Icon-->
                </span>
                <span class="d-none d-sm-inline">Tambah Seting HE</span>
              </a>
          </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div class="table-responsive">
              <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                      <tr class="text-uppercase">
                          <th @click="sort('tgl_setting_he')" class="pl-7 cursor-pointer">
                              <span :class="{ 'text-primary': currentSort == 'tgl_setting_he' }">Setting</span>
                              <span v-if="currentSort == 'tgl_setting_he' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'tgl_setting_he' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th @click="sort('jumlah')" class="pl-7 cursor-pointer d-none d-sm-table-cell">
                              <span :class="{ 'text-primary': currentSort == 'jumlah' }">Jumlah</span>
                              <span v-if="currentSort == 'jumlah' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'jumlah' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th @click="sort('status')" class="pl-7 cursor-pointer d-none d-md-table-cell">
                              <span :class="{ 'text-primary': currentSort == 'status' }">Status</span>
                              <span v-if="currentSort == 'status' && currentSortDir == 'asc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Down-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                              <span v-if="currentSort == 'status' && currentSortDir == 'desc'" class="svg-icon svg-icon-sm svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Navigation/Up-2.svg"/>
                                  <!--end::Svg Icon-->
                              </span>
                          </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                      <tr v-for="(val, key) in listSHR" :key="key">
                          <td class="pl-0 py-8">
                              <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 flex-shrink-0 mr-4">
                                      <div class="symbol-label">
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                      </div>
                                  </div>

                                  <div>
                                      <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Tgl Setting HE</a>
                                      <span class="text-muted font-weight-bold d-block font-size-sm">{{ val.tgl_setting_he | dateIndonesia }}</span>
                                  </div>
                              </div>
                          </td>
                          <td class="d-none d-sm-table-cell">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {{ val.jumlah | nominal }}
                              </span>
                              <span class="text-muted font-weight-bold font-size-sm">
                                  butir
                              </span>
                          </td>
                          <td class="d-none d-md-table-cell">
                              <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                          </td>
                          <td class="text-right pr-0">
                            <b-button v-b-tooltip.hover title="Selesai Pullchick" variant="light" size="sm" class="mr-2" v-if="val.status == 52" @click="selesaiPopupData(val)">
                                <span class="svg-icon svg-icon-success p-0 m-0">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Navigation/Double-check.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                            </b-button>

                            <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="d-none d-sm-inline">Action</span>
                                </template>                                        
                                <b-dropdown-item @click="openDialogForm('FormDetail', val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/poultry-farm/kandang.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Detail</span>
                                </b-dropdown-item>                              
                                <b-dropdown-item v-if="val.status == 170" @click="openDialogForm('FormSettingHE', {_id: val._id, action: 'edit'})">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/poultry-hatchery/setting-he.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Edit Setting HE</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="val.status == 52" @click="openDialogForm('FormPullChick', val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/poultry-hatchery/pullchick.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Pullchick</span>
                                </b-dropdown-item>
                            </b-dropdown>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                      
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Body-->
      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
      </div>

  </div>

    <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
      <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
      >
        <v-date-picker
          v-model="dates"
          scrollable
          range
          color="bg-primary"
          header-color="bg-primary"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modalDate = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="setDate()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

    <v-dialog
    v-model="dialogSelesai"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Selesai Pullchick</v-card-title>

        <v-card-text>
          Apakah data pullchick sudah terisi dengan benar? jika klik lanjutkan anda tidak dapat menginput data pullchick kembali
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogSelesai = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onSelesai">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import ErrorService from "@/core/services/error.service";

import FormDetail from "@/view/pages/nasional/produksi/form/_Detail.vue";
import FormSettingHE from "@/view/pages/nasional/produksi/form/_SettingHE.vue";
import FormPullChick from "@/view/pages/nasional/produksi/form/_PullChick.vue";
import RiwayatSettingHE from "@/view/pages/nasional/produksi/form/riwayat/_SettingHE.vue";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "unggas-nasional-data-produksi-shr-list",
  props: {
    idH: {
      required: true
    }
  },
  data() {
    return {
      search: null,
      dates: [],
      dialogDetail: false,
      modalDate: false,
      curComponent: null,
      sdataForm: null,
      isLoading: true,
      currentPage: 1,
      perPage: 8,
      items: [],
      currentSort:'tgl_setting_he',
      currentSortDir:'asc',
      dialogSelesai: false,
      dataSelesai: null,
    }
  },  
  components: {
    VueContext,
    ContentLoader,
    FormDetail,
    FormSettingHE,
    FormPullChick,
    RiwayatSettingHE,
  },    
  computed: {
    ...mapGetters(["currentUser"]),
    filteredList() {
        const result = this.items.filter((row, index) => {
            if (this.search) {
                let tglSet = new Date(row.tgl_setting_he).getTime()
                let tglAwl = new Date(this.search[0]).getTime()
                let tglAkr = new Date(this.search[1]).getTime()
                if (tglSet >= tglAwl && tglSet <= tglAkr) return true;
            }
            else return true
        })
        return result
    },
    listSHR() {
        let sort = this.filteredList.slice(0).sort((a,b) => {
            let modifier = 1;
            if(this.currentSortDir === 'desc') modifier = -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
        })

        const result = sort.filter((row, index) => {
            let start = (this.currentPage-1)*this.perPage;
            let end = this.currentPage*this.perPage;
            if(index >= start && index < end) return true;
        })
        return result
    },
    totalRows() {
        if (!this.filteredList) {
            return 1
        }
        return this.filteredList.length
    },
    dateView() {
      return (this.search ? this.search.join(' ~ ') : 'Silahkan klik untuk pilih tanggal setting')
    }
  },
  methods: { 
    sort(s) {
        //if s == current sort, reverse
        if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
        }
        this.currentSort = s;
    },
    setDate() {
      if (this.dates.length < 1) {
        ErrorService.message({
          action: 'Peringatan',
          message: 'Silahkan pilih tanggal terlebih dahulu',
        })
        return;
      }
      if (this.dates.length < 2) {
        this.dates.push(this.dates[0])
      }
      this.dates.sort()

      const oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(this.dates[0])
      const secondDate = new Date(this.dates[1])
      const diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
      if (diffDays > 30) {
        ErrorService.message({
          action: 'Peringatan',
          message: 'Range tanggal tidak boleh lebih dari 30 hari',
        })
        return;
      }

      this.search = this.dates.sort()
      this.modalDate = false
    },
    resetDate() {
        this.search = null
        this.dates = []
    },
    openDialogForm(name, data) {
      this.curComponent = name
      this.sdataForm = data
      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh) {
            this.getData()    
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
    selesaiPopupData(val) {
      this.dataSelesai = val._id
      this.dialogSelesai = true
    },
    onSelesai () {
      if (! this.dataSelesai) {
        return
      }
      ProduksiService.selesaiPullChick({ _sid: this.dataSelesai })
        .then((res) => {
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogSelesai = false
            this.dataSelesai = null
        })
        .catch((err) => {
          this.dialogSelesai = false
          this.dataSelesai = null
          return ErrorService.status(err)
        })
    },

    getData () {
      ProduksiService.getSettingHeList({hatchery: this.idH})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.items = []
                this.isLoading = false
                return;
            }
            this.items = res.data.data
            this.isLoading = false
        })
        .catch((err) => {
          this.items = []
          this.isLoading = false
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "SHR" }]);
    if (!this.currentUser.menu.nasional_produksi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
